import {AsyncPipe, NgForOf} from '@angular/common';
import {Component, Input, OnInit} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {DividerModule} from 'primeng/divider';
import {map, Observable} from 'rxjs';
import {
    KeyboardShortcutAssignComponent
} from 'src/app/components/sub-components/settings-personal/keyboard-shortcut-assign/keyboard-shortcut-assign.component';
import {ConfirmDialogComponent} from 'src/app/components/utilities/confirm-dialog/confirm-dialog.component';
import {ObjectDataComponent} from 'src/app/components/utilities/object-data/object-data.component';
import {Command} from 'src/app/enums/core/command.enum';
import {ComponentName} from 'src/app/enums/core/component-name.enum';
import {KeyboardCode} from 'src/app/enums/core/keyboard-code.enum';
import {KeyboardShortcutType} from 'src/app/enums/core/keyboard-shortcut-type.enum';
import {Severity} from 'src/app/enums/core/severity.enum';
import {DEFAULT_KEYBOARD_SHORTCUTS} from 'src/app/helpers/core/keyboard-shortcuts';
import {GAP_BUTTONS, GUTTER_Y, SITE_ROUTING} from 'src/app/helpers/core/site-config.helper';
import {KeyboardShortcutsComponentSimplified} from 'src/app/interfaces/core/keyboard-shortcut-simplified.interface';
import {
    mapKeyboardShortcutsSettingsObjectToKeyboardShortcutSimplifiedObject
} from 'src/app/mappers/keyboard-shortcuts.mapper';
import {ButtonsModule} from 'src/app/modules/buttons/buttons.module';
import {ConfirmDialogService} from 'src/app/services/core/confirm-dialog.service';
import {CoreService} from 'src/app/services/core/core.service';
import {KeyboardShortcutsService} from 'src/app/services/core/keyboard-shortcuts.service';

const RAD_STANDALONE_COMPONENTS = [
    ConfirmDialogComponent,
    KeyboardShortcutAssignComponent,
    ObjectDataComponent
];

const RAD_MODULES = [
    ButtonsModule
];

const PRIME_NG_MODULES = [
    DividerModule
];

@Component({
    selector: 'app-keyboard-shortcuts',
    standalone: true,
    imports: [
        NgForOf,
        AsyncPipe,
        TranslateModule,
        ...RAD_STANDALONE_COMPONENTS,
        ...RAD_MODULES,
        ...PRIME_NG_MODULES
    ],
    templateUrl: './keyboard-shortcuts.component.html'
})
export class KeyboardShortcutsComponent implements OnInit {
    @Input() public readOnly: boolean = false;

    protected keyboardShortcutsComponentsSimplified$!: Observable<KeyboardShortcutsComponentSimplified[]>;
    protected keyboardShortcut$!: Observable<KeyboardCode[]>;
    protected component$!: Observable<ComponentName | null>;

    protected readonly Command = Command;
    protected readonly Object = Object;
    protected readonly GUTTER_Y = GUTTER_Y;
    protected readonly GAP_BUTTONS = GAP_BUTTONS;
    protected readonly Severity = Severity;
    protected readonly ComponentName = ComponentName;
    protected readonly SITE_ROUTING = SITE_ROUTING;
    protected readonly DEFAULT_KEYBOARD_SHORTCUTS = DEFAULT_KEYBOARD_SHORTCUTS;
    protected readonly KeyboardShortcutType = KeyboardShortcutType;

    constructor(
        protected keyboardShortcutsService: KeyboardShortcutsService,
        private confirmDialogService: ConfirmDialogService,
        private coreService: CoreService
    ) {
    }

    public ngOnInit() {
        this.component$ = this.coreService.component$;
        this.keyboardShortcutsComponentsSimplified$ = this.prepareKeyboardShortcutsSimplified();
        this.keyboardShortcut$ = this.keyboardShortcutsService.keyboardShortcut$;
    }

    private prepareKeyboardShortcutsSimplified() {
        return this.keyboardShortcutsService.keyboardShortcuts$.pipe(
            map((keyboardShortcuts) => {
                return mapKeyboardShortcutsSettingsObjectToKeyboardShortcutSimplifiedObject(keyboardShortcuts);
            })
        );
    }

    protected initiateAssignShortcut(componentName: ComponentName, command: Command, value?: string) {
        this.keyboardShortcutsService.reset();
        this.keyboardShortcutsService.activeItem = {componentName, command, value};
    }

    protected revertToDefaultPrompt(componentName?: ComponentName, command?: Command) {
        const fn = () => componentName && command ?
            this.revertToDefault(componentName, command) :
            componentName ?
                this.revertToDefaults(componentName) :
                this.revertAllToDefaults();

        this.confirmDialogService.open(
            command ?
                ('enums.Command.' + command) :
                componentName ?
                    ('enums.ComponentName.' + componentName) :
                    'revertKeyboardShortcutsToDefault',
            'revertKeyboardShortcutToDefaultPrompt',
            fn
        );
    }

    protected removePrompt(componentName: ComponentName, command: Command) {
        const fn = () => this.remove(componentName, command);

        this.confirmDialogService.open('enums.Command.' + command, 'removeKeyboardShortcutPrompt', fn);
    }

    protected remove(componentName: ComponentName, command: Command) {
        this.keyboardShortcutsService.remove(componentName, command);
    }

    protected revertToDefault(componentName: ComponentName, command: Command) {
        this.keyboardShortcutsService.revertToDefault(componentName, command);
    }

    protected revertToDefaults(componentName: ComponentName) {
        this.keyboardShortcutsService.revertToDefaults(componentName);
    }

    protected revertAllToDefaults() {
        this.keyboardShortcutsService.revertAllToDefaults();
    }
}